<dx-popup
  [showTitle]="true"
  [title]="dialogTitle"
  [(visible)]="displayDialog"
  [width]="1200"
  [height]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  [enableBodyScroll]="true"
  (onHidden)="closeDialog()"
>

  <div class="row">

    <!-- formulaire point-facturation -->
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          <h3 class="first">Point de facturation</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="form">

            <div class="dialog-line">
              <div class="label" title="Libellé">Libellé <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Libellé" formControlName="libelle"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Code">Code <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Code" formControlName="code"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Client">Client <span class="danger-color">*</span></div>
              <div class="value">
                <yo-lookup
                  #clientLookup
                  [data]="allClients"
                  [selectedItem]="selectedClient?.id"
                  (onValueChange)="onChangeClient($event)"
                  [placeholder]="'Sélectionner un client'"
                ></yo-lookup>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="PointLivraison">Point de livraison <span class="danger-color">*</span></div>
              <div class="value">
                <yo-tagbox
                  #plcList
                  [data]="allPlcs"
                  [selectedItems]="selectedPlcs"
                  [disabled]="!isSelectedClient()"
                >
                </yo-tagbox>

              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Actif">Actif <span class="danger-color">*</span></div>
              <div class="value">
                <dx-check-box [value]="true" formControlName="actif" ></dx-check-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Synthetique">Facture sans détails <span class="danger-color">*</span></div>
              <div class="value">
                <dx-check-box [value]="true" formControlName="synthetique" ></dx-check-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="DelaiPaiement">Délai de paiement <span class="danger-color">*</span></div>
              <div class="value">
                <dx-number-box placeholder="Délai de paiement (en jours)" formControlName="delaiPaiement"></dx-number-box>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

    <!-- form contact 1 -->
    <div class="col-6">
      <div class="card">
        <div class="card-header">
          <h3 class="first">Contact 1</h3>
        </div>
        <div class="card-body">
          <ng-container
            *ngTemplateOutlet="tplContactForm;context:{form:formContact1}"></ng-container>
        </div>
      </div>
    </div>

  </div>

  <div class="row mt-2">

    <!-- formulaire adresse -->
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h3 class="first">ADRESSE</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="formAdresse">
            <div class="dialog-line">
              <div class="label" title="Nom">Nom <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Nom" formControlName="nom"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Adresse1">Adresse 1 <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Adresse 1" formControlName="adresse1"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Adresse2">Adresse 2</div>
              <div class="value">
                <dx-text-box placeholder="Adresse 2" formControlName="adresse2"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Adresse3">Adresse 3</div>
              <div class="value">
                <dx-text-box placeholder="Adresse 3" formControlName="adresse3"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Telephone">Téléphone <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Téléphone" formControlName="telephone"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Fax">Fax</div>
              <div class="value">
                <dx-text-box placeholder="Fax" formControlName="fax"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="CodePostal">Code Postal <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Code Postal" formControlName="codePostal"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Ville">Ville <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Ville" formControlName="ville"></dx-text-box>
              </div>
            </div>

            <div class="dialog-line">
              <div class="label" title="Email">Email <span class="danger-color">*</span></div>
              <div class="value">
                <dx-text-box placeholder="Email" formControlName="email"></dx-text-box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- form contact 2 -->
    <div class="col">
      <div class="card">
        <div class="card-header">
          <h3 class="first">Contact 2</h3>
        </div>
        <div class="card-body">
          <ng-container
            *ngTemplateOutlet="tplContactForm;context:{form:formContact2}"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <yo-button
      type="submit"
      iconClass="fas fa-save"
      buttonClass="cta-success"
      (onClick)="save()"
      tooltip="Enregistrer">
    </yo-button>
    <yo-button
      class="mg-l-5"
      buttonClass="cta-delete"
      (onClick)="closeDialog()"
      tooltip="Fermer"
      iconClass="fas fa-times">
    </yo-button>
  </div>

</dx-popup>

<ng-template #tplContactForm let-form="form">
  <form [formGroup]="form">
    <div class="dialog-line">
      <div class="label" title="Nom">Nom</div>
      <div class="value">
        <dx-text-box placeholder="Nom" formControlName="nom"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Prenom">Prénom</div>
      <div class="value">
        <dx-text-box placeholder="Prénom" formControlName="prenom"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Telephone1">Téléphone 1</div>
      <div class="value">
        <dx-text-box placeholder="Téléphone 1" formControlName="telephone1"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Telephone2">Téléphone 2</div>
      <div class="value">
        <dx-text-box placeholder="Téléphone 2" formControlName="telephone2"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Fax">Fax</div>
      <div class="value">
        <dx-text-box placeholder="Fax" formControlName="fax"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Email">Email</div>
      <div class="value">
        <dx-text-box placeholder="Email" formControlName="mail"></dx-text-box>
      </div>
    </div>

    <div class="dialog-line">
      <div class="label" title="Commentaires">Commentaires</div>
      <div class="value">
        <dx-text-area placeholder="Commentaires" formControlName="commentaires"></dx-text-area>
      </div>
    </div>
  </form>
</ng-template>
