// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11138(7fa6f7f46c)-C13/01/2025-09:46:38-B13/01/2025-10:07:28' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11138(7fa6f7f46c)-C13/01/2025-09:46:38-B13/01/2025-10:07:28",
  branch: "master",
  latestTag: "6.1",
  revCount: "11138",
  shortHash: "7fa6f7f46c",
  longHash: "7fa6f7f46ca89fd91a8658db8e992f274fd858a0",
  dateCommit: "13/01/2025-09:46:38",
  dateBuild: "13/01/2025-10:07:28",
  buildType: "Ansible",
  } ;
