<dx-popup
  [showTitle]="true"
  title="Impression du bon de livraison"
  [(visible)]="displayDialog"
  [width]="900"
  [height]="'auto'"
  [maxHeight]="800"
  [resizeEnabled]="true"
  [showCloseButton]="true"
  (onHidden)="closeDialog()"
>
  <div class="settings-bl">
  <div>Commentaire :</div>
  <div class="dialog-line">
    <div class="bl-option">
    <yo-tagbox
      [data]="plcList"
      (onValueChange)="onChangePlcComment($event)"
      [selectedItems]="plcSelectedComment"
    >
    </yo-tagbox>
    </div>
    <div class="bl-option">
    <dx-text-area
      class="textarea"
      [(ngModel)]="comment"
      placeholder="Commentaire"
    ></dx-text-area>
      </div>
  </div>

  <div>Message du jour :</div>
    <div class="dialog-line">
    <div class="bl-option">
      <yo-tagbox
        [data]="plcList"
        (onValueChange)="onChangePlcMessage($event)"
      >
      </yo-tagbox>
      </div>
      <div class="bl-option">

      <dx-text-area
        class="textarea"
        [(ngModel)]="message"
        placeholder="Message du jour"
      ></dx-text-area>
        </div>
    </div>
  </div>

  <div class="dialog-line">
        <dx-check-box
          [(ngModel)]="paysage" text="Impression format paysage"
          [value]="false">
        </dx-check-box>
  </div>

  <div class="dialog-line">
    <dx-check-box
      [(ngModel)]="displayTotalAndPOR" text="Total et nombre de portions arrondis livrés"
      [value]="true">
    </dx-check-box>
  </div>

  <div class="custom-button-container-right">
    <yo-button label="Imprimer" iconClass="fas fa-print"
               (onClick)="downloadBonlivraison()">
    </yo-button>
    <yo-button buttonClass="cta-inner-delete" iconClass="fas fa-times" (click)="closeDialog()">
    </yo-button>
  </div>
</dx-popup>
